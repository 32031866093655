<template>
  <section class="fullscreen-video">
    <!--<div class="overlay"></div>-->
    <div class="info container-fluid" :class="{ '-hide': hideInfo }">
      <Observer name="fade-up">
        <div class="text">
          <h2 class="h2 title" v-if="item.title" v-html="item.title"></h2>
          <p class="text" v-if="item.text" v-html="item.text"></p>
          <div class="btn-play" v-if="item.video && item.video.url" @click="onClickPlay">
            <span>{{ $t('Play') }}</span>
          </div>
        </div>
      </Observer>
    </div>

    <div ref="wrapper" class="player-fs" :class="{'-show': played, '-over': isFullscreen}" v-bsl="isMobile && isFullscreen && played">
      <NoSSR>
        <Observer name="fade-up" @enter="enter" @leave="leave" >
          <vue-plyr ref="plyr" :options="plyrOption" :class="{ 'hide-controls': !hideInfo }">
            <video :poster="(isMobile && item.coverMobile && item.coverMobile.url) ? item.coverMobile.url + '?impolicy=' + item.impolicy : item.cover.url + '?impolicy=' + item.impolicy" :src="injected ? ((isMobile && item.videoMobile && item.videoMobile.url) ? item.videoMobile.url+ '?impolicy=' + item.impolicy : item.video.url+ '?impolicy=' + item.impolicy) : ''" crossorigin="anonymous" playsinline>
            <track v-if="item.subtitle && item.subtitle.src && item.subtitle.srclang" kind="captions" :label="item.subtitle.label" :srclang="item.subtitle.srclang" :src="item.subtitle.src" default />
            </video>
          </vue-plyr>
        </Observer>
      </NoSSR>
    </div>
  </section>
</template>

<script>
import NoSSR from 'src/themes/zenith/node_modules/vue-no-ssr'
// import 'plyr/dist/plyr.css'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

if (process.browser) {
  var VuePlyr = require('vue-plyr/dist/vue-plyr.ssr.js')
}

export default {
  props: ['item', 'items', 'slice', 'pagePosition', 'siblings-components'],
  name: 'defy_extreme_fullscreen_video',
  components: {
    VuePlyr,
    NoSSR
  },
  data () {
    return {
      injected: false,
      hideInfo: false,
      played: false,
      isFullscreen: false,
      plyrOption: {
        controls: ['play', 'progress', 'current-time', 'mute', 'volume', 'settings', 'airplay', 'fullscreen', 'captions'],
        captions: {
          active: !!(this.item.subtitle && this.item.subtitle.srclang),
          language: (this.item.subtitle && this.item.subtitle.srclang) ? this.item.subtitle.srclang : 'en',
          fullscreen: {
            enabled: true,
            fallback: true,
            iosNative: true,
            container: null
          }
        }
      }
    }
  },
  computed: {
    localizedUrl() {
      const { url } = currentStoreView()
      return url || '/'
    },
    isMobile() {
      return this.$store.state['zenith-ui'].menu.mobile
    }
  },
  methods: {
    play() {
      if (this.$refs.plyr) {
        this.$refs.plyr.player.play()
      }
    },
    enter() {
      if (!this.injected) {
        this.injected = true
        if (this.$refs.plyr) {
          this.$refs.plyr.player.on('play', () => {
            this.played = true
            this.hideInfo = true
          })
          this.$refs.plyr.player.on('pause', () => {
            this.played = false
          })
          this.$refs.plyr.player.on('enterfullscreen', () => {
            this.isFullscreen = true
            if (this.isMobile) {
              this.scrollTopVideo()
            }
          })
          this.$refs.plyr.player.on('exitfullscreen', () => {
            this.isFullscreen = false
            document.body.style.overflowY = 'auto'
          })
        }
      }
    },
    leave() {
      if (this.$refs.plyr) {
        this.$refs.plyr.player.pause()
      }
    },
    scrollTopVideo() {
      let box = this.$refs.wrapper.getBoundingClientRect()
      let body = document.body
      let docEl = document.documentElement
      let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
      let clientTop = docEl.clientTop || body.clientTop || 0
      let top = box.top + scrollTop - clientTop
      window.scrollTo({
        top: Math.round(top)
      })
      document.body.style.overflowY = 'hidden'
    },
    onClickPlay() {
      this.play()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';

section {
  position: relative;
  height: 56.25vw;
  // max-height: 100vh;

  @include bp(max md) {
    height: 100vh;
  }

  .overlay {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.8);
  }

  .info {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 0;
    transform: translateY(calc(-100% + 40px));
    width: 100%;
    color: var(--zenith-white);
    display: flex;
    justify-content: center;
    align-items: center;

    &.-hide {
      display: none;
    }

    @include bp(max xl) {
      top: 0;
      transform: none;
      height: 100%;
    }

    .text {
      margin: 0 auto;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .text {
        max-width: 410px;
      }

      @include bp(max md) {
        max-width: none;
      }

      .title {
        // width: 80%;
        font-size: em(40);
        line-height: em(40, 40);
        font-weight: 500;
        text-align: center;
        margin-bottom: 0;

        @include bp(max md) {
          font-size: em(30);
          line-height: em(30, 30);
        }
      }

      .text {
        line-height: em(30, 20);
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 0;
      }

      & + .btn-play {
        margin-top: 4rem;
      }
    }
  }

  .player-fs {
    position: relative;
    z-index: 1;
    height: 100%;
    opacity: 1;
    pointer-events: all;

    div {
      height: 100%;
    }

    /deep/ .plyr {
      max-height: none;
    }
  }

}
</style>
